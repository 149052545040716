<template>
  <v-container>
    <div id="users" v-if="user && users && companies">
      <v-card class="elevation-0">
        <v-card-title>
          <v-row class="justify-space-between">
            <v-col cols="6">
              <v-text-field
                class="mr-6"
                v-model="search"
                append-icon="mdi-magnify"
                label="Søg"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="mt-3 text-end">
              <v-btn class="primary" @click="createNew">Create user</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          class="table-cursor"
          :headers="headers"
          :items="users"
          :search="search"
          :options="{ itemsPerPage: 1000 }"
          hide-default-footer
          @click:row="rowClick"
        >
          <template v-slot:[`item.activated`]="{ item }">
            <v-icon v-if="item.activated">mdi-check</v-icon>
          </template>
        </v-data-table>
      </v-card>

      <v-dialog v-model="itemDialog" scrollable max-width="600px">
        <v-card>
          <v-card-title>{{ dialogHeader }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-4">
            <v-form v-model="formValid" ref="form">
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="form.email"
                      :rules="[formRules.requiredRule, formRules.emailRule]"
                      label="Email"
                      :disabled="dialogMode === 'edit'"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6"
                    ><v-select
                      :items="companies"
                      label="Airport"
                      item-text="name"
                      item-value="_id"
                      v-model="form.company"
                      :disabled="dialogMode === 'edit'"
                    ></v-select
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" v-if="dialogMode === 'edit'">
                    <v-text-field
                      v-model="form.name"
                      :rules="[formRules.requiredRule]"
                      label="Name"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-4">
            <div class="d-flex justify-space-between" style="width: 100%">
              <div>
                <v-btn
                  v-if="dialogMode === 'edit'"
                  color="error"
                  text
                  @click="deleteClick"
                >
                  Delete
                </v-btn>
              </div>
              <div>
                <v-btn class="primary--text" text @click="itemDialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  v-if="dialogMode === 'new'"
                  class="primary ml-6"
                  @click="dialogConfirmCreate"
                >
                  Create
                </v-btn>
                <v-btn
                  v-if="dialogMode === 'edit'"
                  class="primary ml-6"
                  @click="dialogConfirmSave"
                >
                  Save
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteDialog" max-width="480">
        <v-card>
          <v-card-title class="text-h6">
            Are you sure you want to delete?
          </v-card-title>

          <v-card-text>
            This will delete the user permanently and cannot be undone.
          </v-card-text>

          <v-card-actions class="py-5">
            <v-spacer></v-spacer>

            <v-btn text @click="deleteDialog = false"> Cancel </v-btn>

            <v-btn class="error ml-6" dark text @click="dialogConfirmDelete">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, email } from "../utils/FormRules";

export default {
  name: "Users",
  data() {
    return {
      itemDialog: false,
      dialogMode: null,
      dialogEditId: null,
      deleteDialog: false,
      search: "",
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "Email", value: "email" },
        { text: "Airport", value: "company.name" },
        { text: "Activated", value: "activated" },
      ],
      defaultForm: {
        email: "",
        name: "",
        company: null,
      },
      form: {},
      formValid: false,
      formRules: {
        requiredRule: required,
        emailRule: email,
      },
    };
  },
  mounted() {
    this.loadCompanies();
    this.loadUsers();
  },
  methods: {
    ...mapActions([
      "loadCompanies",
      "loadUsers",
      "createUser",
      "saveUser",
      "deleteUser",
    ]),
    createNew() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = Object.assign({}, this.defaultForm);
      this.dialogEditId = null;
      this.dialogMode = "new";
      this.itemDialog = true;
    },
    dialogConfirmCreate() {
      this.createUser(this.form);
      this.itemDialog = false;
    },
    dialogConfirmSave() {
      this.saveUser({
        userId: this.dialogEditId,
        user: this.form,
      });
      this.itemDialog = false;
    },
    dialogConfirmDelete() {
      this.deleteUser(this.dialogEditId);
      this.deleteDialog = false;
      this.itemDialog = false;
    },
    rowClick(row) {
      this.form.email = row.email;
      this.form.name = row.name;
      this.form.company = row.company;
      this.dialogEditId = row._id;
      this.dialogMode = "edit";
      this.itemDialog = true;
    },
    deleteClick() {
      this.deleteDialog = true;
    },
  },
  computed: {
    ...mapState(["loaded", "user", "token", "companies", "users"]),
    dialogHeader() {
      return this.dialogMode == "new" ? "Create user" : "Edit user";
    },
  },
};
</script>
<style>
.table-cursor tbody tr:hover {
  cursor: pointer;
}
</style>
