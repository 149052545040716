export const required = (v) => !!v || "Field is required";

export const email = (v) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    v
  ) || "Valid email address is required";

export const number = (v) =>
  !isNaN(parseFloat(v.replace(",", "."))) || "Must be a number";

export const lengthEight = (v) =>
  v.length > 7 || "Must be at least 8 characters";

export const hasUpper = (v) =>
  /[A-Z]/.test(v) || "Must have at least one uppercase letter";

export const hasLower = (v) =>
  /[a-z]/.test(v) || "Must have at least one lowercase letter";

export const hasNumber = (v) => /\d/.test(v) || "Must have at least one number";

export const hasSpecialCharacter = (v) =>
  /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(v) ||
  "Must have at least one special character";
